const AppBar = () => import('./components/AppBar.vue');
const Dashboard = () => import('./views/Dashboard.vue');
const Data = () => import('./views/Metadata.vue');
const Information = () => import('./components/Information.vue');
const InformationContainer = () => import('./views/InformationContainer.vue');
const LandingpageVideo = () => import('./views/LandingpageVideo.vue');
const Map = () => import('./views/Map.vue');
const MapRouteTitle = () => import('./components/MapRouteTitle.vue');
const TheDisclaimer = () => import('./components/TheDisclaimer.vue');
const TheMapSearch = () => import('./components/TheMapSearch.vue');
const TheNavigationRail = () => import('./components/TheNavigationRail.vue');

/** @type {Array<import("vue-router").RouteRecordRaw>} */
export default [
  { path: '/', component: LandingpageVideo, name: 'Landingpage' }, // to do: welcome page
  {
    path: '/topics',
    components: { default: Dashboard, AppBar, TheNavigationRail },
    name: 'Dashboard',
  },
  {
    path: '/map/:kartenansichtId/:visibleLayers?',
    components: {
      default: Map,
      AppBar,
      TheNavigationRail,
      TheDisclaimer,
    },
    children: [
      {
        name: 'Map',
        path: '',
        components: {
          title: MapRouteTitle,
          search: TheMapSearch,
        },
      },
    ],
  },
  {
    path: '/map-embed/:kartenansichtId/:visibleLayers?',
    name: 'MapEmbed',
    component: Map,
  },
  {
    path: '/data/:kartenansichtId',
    components: { default: Data, AppBar, TheNavigationRail, TheDisclaimer },
    children: [
      {
        name: 'Data',
        path: '',
        components: {
          title: MapRouteTitle,
        },
      },
    ],
  },
  {
    path: '/information',
    name: 'Information',
    components: {
      default: InformationContainer,
      AppBar,
      TheNavigationRail,
    },
    children: [
      {
        path: 'ueberwaldatlas',
        component: Information,
        props: {
          pagecontent: 'infoseite-ueberuns',
        },
      },
      {
        path: 'opendatainspire',
        component: Information,
        props: {
          pagecontent: 'infoseite-open-data',
        },
      },
      {
        path: 'wissenswertes',
        component: Information,
        props: {
          pagecontent: 'infoseite-wissenswertes',
        },
      },
      {
        path: 'haftungsausschluss',
        component: Information,
        props: {
          pagecontent: 'infoseite-haftungsausschluss',
        },
      },
      {
        path: 'plattformen',
        component: Information,
        props: {
          pagecontent: 'infoseite-plattformen',
        },
      },
      {
        path: 'kontakt',
        component: Information,
        props: {
          pagecontent: 'infoseite-kontakt',
        },
      },
      {
        path: 'impressum',
        component: Information,
        props: {
          pagecontent: 'infoseite-impressum',
        },
      },
      {
        path: 'datenschutz',
        component: Information,
        props: {
          pagecontent: 'infoseite-datenschutz',
        },
      },
      {
        path: 'barrierefreiheit',
        component: Information,
        props: {
          pagecontent: 'infoseite-barrierefrei',
        },
      },
      // default redirect to home page
      { path: '/:pathMatch(.*)*', redirect: '/' },
    ],
  },
];
